<template>
    <div class="medialist">
         <a-page-header title="User" style="border: 1px solid rgb(235, 237, 240)"/>
         <div class="box_content">
            <a-button class="ant-btn-danger" @click="addUserBtn"  type="primary">
                Add User
            </a-button>
            <a-table :columns="columns" :data-source="data" :rowKey="(record, index) => index" size="middle" :loading="loading">
              <span slot="action" slot-scope="text,data">
                    <a @click="editBtn(data.id)">Edit</a>
                </span>
            </a-table>
          </div>
    </div>

</template>

<script>
const columns = [
  {
    title: 'Email',
    dataIndex: 'Email',
    key: 'Email',
  },
  {
    title: 'BrandName',
    dataIndex: 'BrandName',
    key: 'BrandName',
  },
  {
    title: 'CompanyName',
    dataIndex: 'CompanyName',
    key: 'CompanyName',
  },
  {
    title: 'Contacts',
    dataIndex: 'Contacts',
    key: 'Contacts',
  },
  {
    title: 'ContactsNumber',
    dataIndex: 'ContactsNumber',
    key: 'ContactsNumber',
  },
  {
    title: 'CompanyAddress',
    dataIndex: 'CompanyAddress',
    key: 'CompanyAddress',
  },
  {
    title: 'Website',
    dataIndex: 'Website',
    key: 'Website',
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

import {getUsers,deleteUser} from '@/network/userRequest'
export default {
    name: 'Users',
    components: {getUsers,deleteUser},
    directives: {  },
    data() {
        return {
            data:[],
            columns,
            loading: false,
            visible: false,
        };
    },
    created() {
      this.getList(1)
    },
    methods: {
        getList(page){
          this.loading = true;
          getUsers(page).then(res=>{
              // console.log(res)
              this.loading = false;
              if(res.header.code==200){
                this.total_rows=res.body.total_rows
                this.data=res.body.users
              }
          })
        },
        addUserBtn(){
            this.$router.push('/users/edit')
        },
        editBtn(e){
          this.$router.push({path:'/users/edit',query:{user_id:e}})
        },
        deleteBtn(e,index){
          deleteUser(e)
          .then(res=>{
            // console.log(res)
             if(res.header.code==200){
                this.$message.success("deleted!")
                this.mediaData.splice(index,1)
                return ;
          }})
        },
        changePage(current){
           this.current = current;
           this.getList(current)
        }

    },
};
</script>

<style  scoped>





.mediaimg{width: 100%;height: 100%;}
.delmediabtn{text-align: center;color: #fff; padding: 5px;background-color: rgb(190, 103, 103); }

</style>