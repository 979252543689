import {request} from './request'
import Cookies from 'js-cookie'
import qs from 'qs' 
// const maker_id=Cookies.get("maker_id")
const maker_id=Cookies.get("maker_id")

export function getUsers(page,rows_per_page=10) {
    var maker_id = Cookies.get("maker_id");
    // return false;
    return request({url:'/test/test.php?a=getUsers',params:{page,maker_id,rows_per_page}})
}
export function getUserDetail(user_id) {
    let maker_id = Cookies.get("maker_id");
    // return false;
    return request({url:'/test/test.php?a=getUserDetail',params:{user_id,maker_id}})
}
export function deleteUser(user_id) {
    var maker_id = Cookies.get("maker_id");
    let data={user_id,maker_id}
    // return request({url:'/test/test.php',method:'post',data:qs.stringify(data)})
}
export function addUser(data) {
    data.maker_id= Cookies.get("maker_id");
    return request({url:'/test/test.php?a=addUser',method:'post',data:qs.stringify(data)})
}
export function editUser(data) {
    data.maker_id= Cookies.get("maker_id");
    return request({url:'/test/test.php?a=editUser',method:'post',data:qs.stringify(data)})
}

